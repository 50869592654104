<template>
  <ws-dialog
      v-model="$store.state.avalonPage.displayNewsDialog"
      @save="saveNews"
      :title="dialogTitle"
      :save-text="$store.state.avalonPage.newNews ? $t('Create') : $t('Save')"
      fullscreen-width="400"
      fullscreen-align="left"
      not-centered
      fullscreen
      portal

  >
    <ws-text-field
        v-model="entityData.title"
        :label="$t('Title')"
        :placeholder="$t('EnterName')"
    />

    <ws-text-field
        class="mt-6"
        v-model="entityData.description"
        max-chars="300"
        :label="$t('ShortDescription')"
        :placeholder="$t('EnterShortDescription')"
        area
    />

    <!-- Categories select-->
    <v-menu
        @input="!$event ? displayEditCategory = false : null"
        offset-y
        bottom
        :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-sheet    v-bind="attrs" v-on="on">
          <ws-select
              style="pointer-events: none"
              class="pointer mt-5"
              :value="entityData.categories"
              :items="categoriesSelect"
              :placeholder="$t('Selectcategory')"
              :label="$t('Category')"
              multiple
          />
        </v-sheet>

      </template>
      <v-sheet class="pa-5 pb-6">

        <!-- Select Category-->
        <div v-if="!displayEditCategory">

          <v-hover
              v-for="(item , i) in categoriesSelect" :key="i"
              #default="{hover}"
          >
            <v-sheet
                @click="selectCategory(item)"
                class="px-3 py-1 d-flex justify-space-between align-center wsRoundedLight mb-2 pointer"
                :style="`border : 1px solid ${wsBACKGROUND}`"
            >

              <div class="d-flex align-center">
                <ws-check-box
                    @input="selectCategory(item)"
                    :value="entityData.categories.includes(item.value)"
                />
                <h5 style="font-size : 14px"> {{ item.text }}</h5>
              </div>

                <div style="min-height: 36px">
                  <v-fade-transition>
                  <v-btn v-if="hover" @click="openEditCategory(item)" icon :color="wsACCENT">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  </v-fade-transition>
                </div>
            </v-sheet>
          </v-hover>

          <ws-button
              class="mt-4"
              label="AddCategory"
              @click="openNewCategory"
              block
          />

        </div>
        <!-- Edit Category-->
        <div v-else>
          <div
              class="d-flex justify-space-between align-center ">
            <h5 @click="displayEditCategory = false"
                :style="`color : ${wsACCENT}`"
                class="pointer text-no-wrap"
            >
              <v-icon class="mr-2" :color="wsACCENT">mdi-arrow-left</v-icon>
              {{ $t('GoBack') }}
            </h5>
            <h5 class="text-right shorten-text">{{ !categoryEntityData.uuid ? $t('AddCategory') : $t('EditCategory') }}</h5>
          </div>

          <ws-text-field
              class="mt-6 "
              @change="editCategory(item)"
              v-model="categoryEntityData.text"
              :label="$t('Name')"
              :placeholder="$t('EnterCategoryName')"
              avalon-style
          />

          <div  class="d-flex justify-center mt-3 ">
            <v-btn
                v-if="categoryEntityData.value"
                @click="openDeleteCategory"
                :color="wsACCENT"
                icon
            >
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </div>

        </div>

      </v-sheet>
    </v-menu>

    <image-param-picker

        :label="$t('NewsImage')"
        v-model="entityData.img"
    />

    <ws-text-field
        class="mt-5"
        v-model="entityData.alias"
        :label="$t('Alias')"
        :placeholder="aliasPlaceholder"
        :error="aliasError"
        @input="aliasError = false"
        :tooltip="`${$t('NewsWillBeAvailableByLink')} : ${businessPublicLink('' , true)}/news/${entityData.alias || aliasPlaceholder}`"
    />

    <ws-date-picker
        class="mt-6"
        v-model="entityData.date"
        :label="$t('DatePublished')"
        :placeholder="datePlaceholder"
    />

    <ws-dialog
        v-if="displayDeleteCategory"
        v-model="displayDeleteCategory"
        @delete="deleteCategory"
        :title="$t('DeleteCategory')"
        display-confirm-delete
        close-delete
    />




  </ws-dialog>
</template>

<script>

import {mapActions, mapState} from "vuex";

export default {
  name: "avalonNewNewsDialog",
  components : {
    imageParamPicker : () => import("@/components/AvalonEditor/UI/imageParamPicker")
  },
  data() {
    return {
      displayEditCategory : false,
      categoryEntityData : {
      },
      entityData : {
        categories : []
      },
      aliasPlaceholder : '',
      datePlaceholder  : '',
      displayDeleteCategory : false,
      aliasError : false,

      bufferAlias : null,
    }
  },
  computed : {
    ...mapState('avalon' , [
      'selectedNews' ,
      'selectedLang' ,
    ]),
    ...mapState('avalonPage' , [
      'selectedNewsAlias',
      'newNews',
    ]),
    ...mapState('news' , [
      'categoriesSelect',
    ]),

    dialogTitle() {
      if ( this.$store.state.avalonPage.copyNews ) {
        return this.$t('CopyNews')
      }
      if ( this.$store.state.avalonPage.newNews) {
        return this.$t('AddNews')
      }
      return this.$t('EditNews')
    },
  },
  methods : {
    ...mapActions('news', [
      'GET_ADMIN_BUSINESS_NEWS_ONE',
      'ADD_EDIT_BUSINESS_NEWS',
      'GET_NEWS_CATEGORIES',
      'ADD_EDIT_NEWS_CATEGORY',
      'DELETE_NEWS_CATEGORY',
      'CHECK_NEWS_ALIAS',
    ]),
    selectCategory(item) {
      if ( !this.entityData.categories.includes(item.value) ) {
        this.entityData.categories.push(item.value)
      } else {
        let index = this.entityData.categories.findIndex(el => el === item.value )
        this.entityData.categories.splice(index , 1)
      }
    },
    async deleteCategory() {
      await this.DELETE_NEWS_CATEGORY(this.categoryEntityData.value)
      this.categoryEntityData = {}
      this.displayEditCategory = false
      this.displayDeleteCategory = false
    },
    openDeleteCategory( ) {
      this.displayDeleteCategory = true
    } ,
    async openNewCategory() {
      let data = { lang : this.selectedLang , text : this.$t('NewCategory') }
      let result = await this.ADD_EDIT_NEWS_CATEGORY(data)
      if ( !result ) {
        return
      }
      this.categoryEntityData = result
      this.displayEditCategory =  true
    },
    async editCategory() {
      this.categoryEntityData.lang = this.selectedLang
      await this.ADD_EDIT_NEWS_CATEGORY(this.categoryEntityData)
    },
    openEditCategory(item) {
      this.categoryEntityData = this.COPY(item)
      this.displayEditCategory =  true
    },

    async saveNews() {
      if ( this.entityData.alias && this.entityData.alias !== this.bufferAlias ) {
        let result = await this.CHECK_NEWS_ALIAS(this.entityData.alias)
        if ( !result ) {
          this.aliasError = true
          return this.notify(this.$t('NewsWithAliasExists'), 'warning')
        }
      }
      this.entityData.lang = this.selectedLang
      if ( this.$store.state.avalonPage.copyNews ) {
        this.entityData.copy_source = this.selectedNewsAlias
      }
      let result = await this.ADD_EDIT_BUSINESS_NEWS(this.entityData)
      if (!result) { return }

      if ( this.$store.state.avalonPage.newNews ) {
        this.notify(this.$t('NewsDraftCreated'))
        this.$store.state.avalon.newsSelect.unshift(result)
        this.$router.push(this.businessPublicLink(`news/${result.alias}/editor`))
      } else {
        let index = this.$store.state.avalon.newsSelect.findIndex(el => el.uuid === result.uuid )
        if ( index === -1 ) {
          return
        }
        this.$store.state.avalon.newsSelect[index] = result
        this.$store.state.avalon.newsSelect = this.COPY(this.$store.state.avalon.newsSelect)
        this.notify(this.$t('ChangesSaved'))
      }

      this.$store.state.avalonPage.displayNewsDialog = false

    },

    async loadPage() {
      if ( this.newNews ) { return }
      let result  = await this.GET_ADMIN_BUSINESS_NEWS_ONE({alias : this.selectedNewsAlias , lang : this.selectedLang})
      if ( !result ) {
        return this.notify(this.$t('NetworkError'))
      }
      this.entityData = result
      this.bufferAlias = result.alias
    },

  },
  mounted() {
    if ( this.categoriesSelect.length === 0 ) {
      this.GET_NEWS_CATEGORIES(this.selectedLang)
    }

    this.loadPage()
  },
  beforeMount() {
    this.aliasPlaceholder = this.$uuid.v4()
    this.datePlaceholder  = this.PARSE_DATE(new Date() ,true)
  },
  beforeDestroy() {
    this.$store.state.avalonPage.newNews = false
    this.$store.state.avalonPage.copyNews = false
    this.$store.state.avalonPage.selectedNewsAlias = false
  }
}
</script>

<style scoped>

</style>